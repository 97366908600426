/*! -----------------------------------------------------------------------------------

    Template Name: Cuba Admin
    Template URI: http://angular.pixelstrap.com/cuba
    Description: This is Admin theme
    Author: Pixelstrap
    Author URI: https://themeforest.net/user/pixelstrap

-----------------------------------------------------------------------------------


 */

/* ---------------------
	*** Utils ***
-----------------------*/


@import "utils/variables";

:root {
  --theme-deafult: #33dfeb; //#0aced5
  --theme-secondary: #0a51d5;
}

/* ---------------------
	*** Icons Scss ***
-----------------------*/
@import "vendors/whether-icon";
@import "vendors/flag-icon";
@import "vendors/icofont";
@import "vendors/themify";
@import "vendors/simple-line-icon";
@import "vendors/material-design-icon";
@import "vendors/pe7-icon";
@import "vendors/typicon-icon";
@import "vendors/ionic-icon";
@import "vendors/svg-icon";

/* ---------------------
	*** Base ***
-----------------------*/

@import "base/reset";
@import "base/typography";


/* ---------------------
	*** Components ***
-----------------------*/

@import "components/according.scss";
@import "components/alert.scss";
@import "components/avatars.scss";
@import "components/badge.scss";
@import "components/bookmark.scss";
@import "components/breadcrumb.scss";

@import "components/buttons.scss";

@import "components/card.scss";
@import "components/color.scss";
@import "components/dropdown.scss";
@import "components/form-input.scss";
@import "components/form-wizard.scss";
@import "components/forms.scss";
@import "components/icons.scss";
@import "components/list.scss";
@import "components/loader.scss";
@import "components/popover.scss";
@import "components/print.scss";
@import "components/radio.scss";
@import "components/ribbon.scss";
@import "components/switch.scss";
@import "components/tab.scss";
@import "components/table.scss";
@import "components/touchspin.scss";

@import "components/angular-plugin.scss";
@import "components/calendar.scss";
@import "components/datatable.scss";
@import "components/datepicker.scss";

@import "components/typeahead-search";
@import "components/rating";
@import "components/tour";


/* ---------------------
	*** Pages ***
-----------------------*/

@import "pages/blog.scss";
@import "pages/bookmark-app.scss";
@import "pages/cart.scss";
@import "pages/chart.scss";
@import "pages/chat.scss";
@import "pages/checkout.scss";
@import "pages/comingsoon.scss";
@import "pages/contacts.scss";
@import "pages/dashboard_2.scss";
@import "pages/dashboard_default.scss";
@import "pages/ecommerce.scss";
@import "pages/email-application.scss";
@import "pages/errorpage.scss";
@import "pages/faq.scss";
@import "pages/file.scss";
@import "pages/gallery.scss";
@import "pages/internationalization.scss";
@import "pages/job-search.scss";
@import "pages/kanban.scss";
@import "pages/knowledgebase.scss";
@import "pages/language.scss";
@import "pages/learning.scss";
@import "pages/login.scss";
@import "pages/megaoption.scss";
@import "pages/order-history.scss";
@import "pages/page.scss";
@import "pages/pricing.scss";
@import "pages/progress.scss";
@import "pages/projectlist.scss";
@import "pages/social-app.scss";
@import "pages/task.scss";
@import "pages/timeline-v.scss";
@import "pages/timeliny.scss";
@import "pages/user-profile.scss";
@import "pages/wishlist.scss";


/* ---------------------
	*** Themes ***
-----------------------*/

@import "themes/dark.scss";
@import "themes/dark-sidebar.scss";
@import "themes/theme-customizer.scss";


/* ---------------------
	*** Layout ***
-----------------------*/

@import "layout/footer.scss";
@import "layout/grid.scss";
@import "layout/header.scss";
@import "layout/navs.scss";
@import "layout/search.scss";
@import "layout/select2.scss";
@import "layout/sidebar.scss";
@import "layout/rtl.scss";
@import "layout/box-layout.scss";


// input {
//   padding: 7px 9px !important;
//   border-color: lightgray !important;
//   border-style: solid !important;
//   border-width: thin !important;
//   border-radius: 5px !important;
// }

.formctrl {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.postit {
  // position: absolute;
  right: 0px;
  width: 500px;
  font-size: 12px;
  background-color: #e1fad6;
  padding: 10px;
  // margin-top: -60px;
  margin-right: 16px;
  border-radius: 10px;
}

.help {
  background-color: transparent !important;
  border-color: transparent !important;
  text-align: center !important;
  border: none !important;
  padding: 0px;
  margin-left: 8px;
  cursor: default;
}
